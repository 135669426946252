/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import { configStyle } from '../services/configStyle';
import Loader from "../components/loader";
import { v4 as uuidv4 } from 'uuid';
import { obtenerTokenPDFVideo, enviarVideoRostro, status, cancelarRequest, sendTime, statusData, statusError } from '../services/api';
import { isIOS, isMobileSafari, isFirefox, isMobile, isIPad13, withOrientationChange } from 'react-device-detect';
import ConnectionClient from '../services/ConnectionClientWebRTC';
import LoaderSpoof from "../components/loaderSpoof";
import RecordRTC from "recordrtc"
import * as tf from '@tensorflow/tfjs';
// import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
// //import * as faceapi from 'face-api.js'
// import * as faceapi from '@vladmandic/face-api'

const tmImage = require('@teachablemachine/image');

const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "No identificamos tu rostro, por favor centra tu rostro en el área marcada."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjDistancia = "Por favor asegúrate de centrar tu rostro en el área marcada y captura nuevamente."
const msjAudio = "No logramos capturar audio en tu captura, asegúrate que tu micrófono funciona correctamente e intenta nuevamente."
const msjError = "Lo sentimos no hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente"
const msjMov = "No logramos validar tus movimientos, por favor sigue las instrucciones e intenta nuevamente"
const msSinMovBoca = "Por favor asegúrate de centrar tu rostro en el área marcada y captura nuevamente."
const msSinManos = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const mjListaMov = "No logramos validar tus movimientos, por favor sigue las instrucciones e intenta nuevamente"
const mjNoRostro = "No identificamos tu rostro, por favor centra tu rostro en el área marcada."

class CameraVideoTokenIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        isRecording: false,
        isComplete: false,
        isStart: true,
        uuid: null,
        errorToken: false,
        dataUser: [],
        dataOtorgante: [],
        codes: [],
        face: {},
        errorMessage: "",
        errorMessage2: "",
        intents: 0,
        apikey: "",
        hasResponse: false,
        conteo: false,
        codec: "video/webm;codecs=vp8",
        errorF: "",
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        loaderNum: false,
        timeInit: 0,
        arrayResult: [],
        modelReady: null,
        listoModelo: false,
        textoResult: "",
        modelframes: null,
        frameCount: 0,
        startTime: null,
        fpsInterval: 0,
        then: null,
        now: null,
        init: false,
        token: null,

        faceArray: [],
        ModelURL: process.env.PUBLIC_URL + "/models",
        arrayListo: false,
        open: 0,
        close: 0,
        threshold: [],
        aspectPorcent: [],
        registroInicio: 0,
        registroFin: 0,
        readyDM: true,
        estable: false,
        audio: "",
        arrayMOv: [],

        elementAudioLeft: null,
        elementAudioRight: null,
        elementAudioMouth: null,
        fizq: false,
        fder: false,
        fboca: false,
        fbocaCerrada: false,
        soporte: false,
        suportMediaRecorder: true
    }

    async componentDidMount() {

        localStorage.setItem("threshold", []);
        localStorage.setItem("stdDev", -1)


        tf.enableProdMode();
        if (isFirefox && !isMobile) {
            this.state.codec = "video/webm;codecs=vp9"
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }

        let codesLS = localStorage.getItem("codes")
        if (codesLS !== null) {
            this.setState({ codes: JSON.parse(codesLS) });
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let face = localStorage.getItem("face")
        if (face !== null) {
            let id_t = uuidv4();
            localStorage.setItem("uuidTrx", id_t)
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }

        this.state.pathname = window.location.pathname;

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }

        this.localStream = window.navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                width: { ideal: 640 },
                height: { ideal: 480 },
                advanced: [
                    { width: 960, height: 720 }
                ]
            }
        });

        this.canvas = document.querySelector('canvas');
        this.width = 320;
        this.height = 0;
        this.video = document.querySelector('video');
        this.elemento = document.getElementById("cuadro");
        this.cuenta = document.getElementById("numeros");
        this.titulo = document.getElementById("head_shop");
        let component = this;
        const urlCreator = window.URL || window.webkitURL;
        let mediaRecorder = null;
        let chunks = [];

        status("videotoken", "Inicio videotoken")
        //console.log("VIDEOTOKEN");

        this.localStream.then(function (mediaStream) {
            component.video.srcObject = mediaStream;
            component.video.onloadedmetadata = function (e) {
                component.video.play();
                    if (mediaStream.getVideoTracks().length > 0) {
                        component.setState({ streamStarted: true, errorVideo: false })
                        if(!window.MediaRecorder){
                            console.log("No soporta MediaRecorder");
                            component.setState({ suportMediaRecorder: false }); //Negar el estado
                        } else {
                            let mymeType = null
                            // si es IOS envíe si puede grabar o no, si soporta el mediarecording
                            if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
                                mymeType = 'video/webm; codecs=vp9';
                                console.log("Si soporta MediaRecorder");
                            } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
                                mymeType = 'video/webm; codecs=vp8';
                                console.log("no soporta MediaRecorder");
                            }
                            let type = isIOS ? 'video/mp4' : mymeType;
                            const optionNormal = {
                                type: 'video',
                                //type: 'audio',
                                mimeType: type,
                            };

                            component.recorder = new RecordRTC(mediaStream, optionNormal);
                            // console.log("this.recorder", component.recorder);
                            component.setState({ listoModelo: true });
                        }

                    } else {
                        console.log("No disponibles");
                        component.setState({ errorVideo: true })
                    }
            };
            component.video.addEventListener('canplay', function (ev) {
                component.height = component.video.videoHeight / (component.video.videoWidth / component.width);
                component.canvas.setAttribute('width', component.video.videoWidth);
                component.canvas.setAttribute('height', component.video.videoHeight);
            }, false);
        }).catch(e => {
            console.trace(e);
            this.setState({ errorVideo: true })
        });

        //Carga del audio player
        this.elementAudioMouth = document.getElementById("audioMouth")
        this.elementAudioThanks = document.getElementById("audioThanks")
        this.elementAudioHello = document.getElementById("audioHello")
        this.elementAudioRight = document.getElementById("audioRight")
        this.elementAudioLeft = document.getElementById("audioLeft")
        this.elementAudioClose = document.getElementById("audioClose")

    }

    msjsError = (mensaje) => {
        let eMessage = msjRostro
        if (mensaje === "No se encontro rostro") {
            eMessage = msjRostro
        } else if (mensaje === "Se detecto cubrebocas") {
            eMessage = msjCubrebocas
        } else if (mensaje === "Condiciones de luz no adecuadas") {
            eMessage = msjLuz
        } else if (mensaje === "Imagen borrosa") {
            eMessage = msjBorrosa
        } else if (mensaje === "Se detectaron multiples caras") {
            eMessage = msjMultiple
        } else if (mensaje === "No logramos entender la frase, intente nuevamente") {
            eMessage = msjFrase
        } else if (mensaje === "Falta de luz") {
            eMessage = msjObscuridad
        } else if (mensaje === "Error frase") {
            eMessage = msjFrase2
        } else if (mensaje === "area_frame_base") { //1
            eMessage = msjDistancia
        } else if (mensaje === "sin_movimiento") { //2
            eMessage = msjMov
        } else if (mensaje === "No se envio lista de movimientos") { //3
            eMessage = mjListaMov
        } else if (mensaje === "No se encontro rostro") {   //4
            eMessage = mjNoRostro
        } else if (mensaje === "area_foto") {   //5
            eMessage = msjDistancia
        } else if (mensaje === "se_detectaron_manos") {   // 8
            eMessage = msSinManos
        }
        console.log("Mensaje de error", mensaje);
        return eMessage;
    }

    contador = () => {
        var cont = 0
        let intervalo = setInterval(() => {
            cont++;
            if (!this.state.hasResponse) {
                if (cont > 38) {
                    //console.log("sali del contador por tiempo");
                    if (!isIOS) {
                        cancelarRequest()
                    }
                    clearInterval(intervalo);
                    this.setState({ errorMessage: msjError, errorToken: true, errorF: "Error en el procesamiento de video", token: null, loading: false, hasResponse: true })
                    //this.titulo.innerHTML = "<p>Por favor <b>sigue</b> las <b>instrucciones</b>, al aparecer</p>";
                    document.getElementById('video_wrt_environment').classList.remove("blur_video")
                }
            } else {
                //console.log("sali del contador");
                clearInterval(intervalo);
            }
        }, 1000);
    }

    getRandom = () => {
        let myArray = []
        for (var i = 0; i < 1; i++) {
            var j = Math.floor(Math.random() * (2));
            console.log("random", j);
            if (j === 1) {
                myArray.push("Izquierda")
            } else {
                myArray.push("Derecha")
            }
        }
        var boca = Math.floor(Math.random() * (2));
        myArray.push(boca)
        console.log("arrayMOv:", myArray);
        this.setState({ arrayMOv: myArray })
    }

    takepictureIOS = () => {
        console.log("webrtc");
        const that = this;
        document.getElementById("btnVideo").disabled = 'true';
        document.getElementById('video_wrt_environment').classList.remove("blur_video")
        this.getRandom()

        this.setState({ isStart: false, loading: false, errorToken: false, errorMessage: "", errorMessage2: "", loaderNum: true })
        let maquina4 = document.getElementById('maquina-escribir4')
        this.maquinaEscribir3("¡Empecemos!", 50, maquina4)

        this.elementAudioHello.play()

        this.setState({ init: true });
        setTimeout(() => {

            obtenerTokenPDFVideo(this.state.uuid)
                .then((response) => {
                    if (response.status === 200) {
                        //console.log(response.data.payload);
                        let listCodes = response.data.payload.code;
                        let Rcreated = response.data.payload.created;
                        let Rexpire = response.data.payload.expire;
                        setTimeout(async () => {
                            that.setState({ loaderNum: false })
                            that.setState({ isRecording: true, isComplete: false, conteo: true })

                            const token = listCodes;

                            const connectionClient = new ConnectionClient();
                            let peerConnection1 = null;

                            const localVideo = document.getElementById('video_wrt_environment');
                            localVideo.autoplay = true;
                            localVideo.muted = true;
                            var listaImg = []
                            that.setState({ threshold: [] });
                            async function beforeAnswer(peerConnection) {
                                that.localStream.then(function (mediaStream) {
                                    mediaStream.getTracks().forEach(track => peerConnection.addTrack(track, mediaStream));
                                })
                                peerConnection1 = peerConnection;
                                let dataChannel = null;
                                let interval = null;
                                let timeoutt = null;
                                let eMessage = ""
                                function onMessage({ data }) {
                                    const responseWRT = JSON.parse(data);
                                    //console.log("responseWRT", responseWRT.res);
                                    let mensaje = (responseWRT.res.message_client) ? responseWRT.res.message_client : ""
                                    //console.log("mensaje", mensaje);
                                    let timeInterval = 100
                                    that.getTiempo()
                                    if (responseWRT.status === 200) {
                                        let msj = responseWRT.res.payload.message
                                        if (msj === "OK" || msj === "Ok") {
                                            let liveness = responseWRT.res.payload.liveness
                                            let intervaloMov = setInterval(() => {
                                                if (that.state.readyDM) {
                                                    clearInterval(intervaloMov);
                                                    if (liveness === "Vivo") {
                                                        that.setLocalStorageResultFace(responseWRT.res.payload)
                                                        statusData("responseWRT", "si mov")
                                                        setTimeout(() => {
                                                            that.props.history.push("/finalizado")
                                                        }, 50);
                                                    } else {
                                                        that.setLocalStorageResultFace(responseWRT.res.payload)
                                                        setTimeout(() => {
                                                            that.props.history.push("/finalizado")
                                                        }, 50);
                                                    }
                                                    that.setState({ loading: false, intents: 0, hasResponse: true })
                                                }
                                            }, timeInterval);

                                        } else {
                                            that.setState({ errorMessage: msj, errorMessage2: "", errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                            that.setState({ errorCapture: true });
                                            localVideo.classList.remove("blur_video");
                                        }
                                        peerConnection.close()
                                    } else if (responseWRT.status === 500 || responseWRT.status === 400) {
                                        let intervaloMov = setInterval(() => {
                                            if (that.state.readyDM) {
                                                clearInterval(intervaloMov);
                                                eMessage = that.msjsError(mensaje);
                                                that.setState({ errorMessage: eMessage, errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                                that.statusF()
                                                peerConnection.close()
                                            }
                                        }, timeInterval);

                                    } else if (responseWRT.status === 404) {
                                        let intervaloMov = setInterval(() => {
                                            if (that.state.readyDM) {
                                                clearInterval(intervaloMov);
                                                that.setState({ errorMessage: msjError, errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                                that.statusF()
                                                peerConnection.close()
                                            }
                                        }, timeInterval);

                                    } else if (responseWRT.status === 504) {
                                        let intervaloMov = setInterval(() => {
                                            if (that.state.readyDM) {
                                                clearInterval(intervaloMov);
                                                that.setState({ errorMessage: msjError, errorToken: true, errorF: responseWRT.res.message_client, token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                                that.statusF()
                                                peerConnection.close()
                                            }
                                        }, timeInterval);

                                    } else {
                                        let intervaloMov = setInterval(() => {
                                            if (that.state.readyDM) {
                                                clearInterval(intervaloMov);
                                                that.setState({ errorMessage: "El servicio no se encuentra disponible, lo solucionaremos en breve", errorToken: true, errorF: "Error en la captura", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                                                that.statusF()
                                                peerConnection.close()
                                            }
                                        }, timeInterval);


                                    }
                                    //that.titulo.innerHTML = "<p>Por favor <b>realizas</b> las <b>acciones</b> que se te indican, al aparecer</p>";
                                    localVideo.classList.remove("blur_video")
                                }
                                let intentos = 0
                                function onDataChannel({ channel }) {
                                    if (channel.label !== 'ping-pong') {
                                        return;
                                    }
                                    dataChannel = channel;
                                    dataChannel.addEventListener('message', onMessage);
                                    that.setState({ init: true });
                                    var myArray = that.state.arrayMOv
                                    let maquina4 = document.getElementById('maquina-escribir4')
                                    interval = setInterval(() => {
                                        intentos++;
                                        if(intentos === 1){
                                            if (myArray[1] === 0) {
                                                that.maquinaEscribir3("Abre tu boca lo más que puedas ", 50, maquina4)
                                                that.setState({ fboca: true });
                                                that.elementAudioMouth.play();
                                            } else {
                                                that.maquinaEscribir3("Gira tu rostro completamente a la " + myArray[0], 50, maquina4)
                                                if (myArray[0] === "Izquierda") {
                                                    that.elementAudioLeft.play()
                                                    that.setState({ fizq: true });
                                                } else {
                                                    that.setState({ fder: true });
                                                }
                                            }
                                        }
                                        if (intentos === 6) {
                                            if (myArray[1] === 0) {
                                                that.maquinaEscribir3("Cierra tu boca", 50, maquina4)
                                                that.setState({ fbocaCerrada: true, fboca: false });
                                                that.elementAudioClose.play()
                                            }
                                        }
                                        if (intentos === 8) {
                                                that.maquinaEscribir3("¡Gracias!", 50, maquina4)
                                                that.elementAudioThanks.play()
                                        }
                                        if (intentos === 11) {
                                            that.setState({ fizq: false, fder: false, fboca: false, fbocaCerrada: false });
                                            if (myArray[1] === 0) {
                                                that.maquinaEscribir3("Gira tu rostro completamente a la " + myArray[0], 50, maquina4)
                                                if (myArray[0] === "Izquierda") {
                                                    console.log(that.elementAudioLeft);
                                                    that.elementAudioLeft.play()
                                                    that.setState({ fizq: true });
                                                } else {
                                                    console.log(that.elementAudioRight);
                                                    that.elementAudioRight.play()
                                                    that.setState({ fder: true });
                                                }
                                            } else {
                                                that.elementAudioMouth.play();
                                                that.maquinaEscribir3("Abre tu boca lo más que puedas", 50, maquina4)
                                                that.setState({ fboca: true });
    
                                            }
                                        }
                                        if (intentos === 16) {
                                            if (myArray[1] !== 0) {
                                                that.maquinaEscribir3("Cierra tu boca", 50, maquina4)
                                                that.setState({ fbocaCerrada: true, fboca: false });
                                                that.elementAudioClose.play()
                                            }
                                        }
                                        if (intentos === 20) {
                                            that.setState({ init: false });
                                            that.setState({ estable: true })
                                            that.setState({ isRecording: false });
                                            that.maquinaEscribir3("¡Gracias!", 50, maquina4)
                                            that.elementAudioThanks.play()
                                            that.setState({ fbocaCerrada: false, fboca: false });
                                            that.setState({ fizq: false, fder: false, fboca: false,fbocaCerrada: false });
                                        }
                                        if (intentos === 21) {
                                            that.setState({ init: false });
                                            that.setState({ loading: false, hasResponse: false });
                                            dataChannel.send("finish_record");
                                        }
                                        if (intentos === 26) {
                                            that.setState({ isComplete: true, estable: false, loading: true, arrayListo: true, faceArray: listaImg, registroInicio: Date.now() })
                                            localVideo.classList.add("blur_video")
                                            clearInterval(interval);
                                        }
                                    }, 500);
                                    timeoutt = setTimeout(() => {
                                        let listaDeMovimientos = []
                                        if (that.state.arrayMOv[1] === 0) {
                                            listaDeMovimientos = [
                                                {
                                                    "inicio": 1,
                                                    "fin": 5,
                                                    "elementoIdentificar": "Boca",
                                                    "direccion": "apertura",
                                                    "movimientoCara": []
                                                },/*{
                                                    "inicio" : 5,
                                                    "fin" : 9,
                                                    "elementoIdentificar" : "Cara",
                                                    "direccion" : (that.state.arrayMOv[0]=== "Izquierda") ? "izquierda" : "derecha",
                                                    "movimientoCara":[]
                                                },*/ {
                                                    "inicio": 5,
                                                    "fin": 10,
                                                    "elementoIdentificar": "Cara",
                                                    "direccion": (that.state.arrayMOv[0] === "Izquierda") ? "izquierda" : "derecha",
                                                    "movimientoCara": []
                                                }
                                            ]
                                        } else {
                                            listaDeMovimientos = [
                                                {
                                                    "inicio": 1,
                                                    "fin": 5,
                                                    "elementoIdentificar": "Cara",
                                                    "direccion": (that.state.arrayMOv[0] === "Izquierda") ? "izquierda" : "derecha",
                                                    "movimientoCara": []
                                                }, /*{
                                                    "inicio" : 5,
                                                    "fin" : 9,
                                                    "elementoIdentificar" : "Cara",
                                                    "direccion" : (that.state.arrayMOv[1]=== "Izquierda") ? "izquierda" : "derecha",
                                                    "movimientoCara":[]
                                                },*/{
                                                    "inicio": 5,
                                                    "fin": 10,
                                                    "elementoIdentificar": "Boca",
                                                    "direccion": "apertura",
                                                    "movimientoCara": []
                                                }
                                            ]
                                        }
                                        let dataJSON = JSON.stringify(listaDeMovimientos);
                                        let contenedor = "dco-bucket-prod"
                                        let tipoVideo = (localStorage.getItem("apikeyOtorgante") === "tmIerl5JiLpbbx0ntRBC7cjoBeDobQep") ? "FACE_ANALYTICS" : "FACE_SPOOF"
                                        let envio = JSON.stringify({ uuid: that.state.uuid, created: Rcreated, expire: Rexpire, bucket: contenedor, service: "videotoken-movimientos", ios: isIOS, mobilesafari: isMobileSafari, isIPad: isIPad13, type: tipoVideo, isMobile: isMobile, key: localStorage.getItem("apikeyOtorgante"), transaccion: (localStorage.getItem("uuidTrx")), cliente: (localStorage.getItem("uuidUser")), tipo_prueba: (localStorage.getItem("tipoPrueba")), data_JSON: dataJSON })
                                        dataChannel.send(envio);
                                    }, 800);
                                }
                                peerConnection.addEventListener('datachannel', onDataChannel);
                                const { close } = peerConnection;
                                peerConnection.close = function () {
                                    that.getTiempo()
                                    if (dataChannel) {
                                        dataChannel.removeEventListener('message', onMessage);
                                    }
                                    if (interval) {
                                        clearInterval(interval);
                                    }
                                    if (timeoutt) {
                                        clearTimeout(timeoutt)
                                    }
                                    return close.apply(this, arguments);
                                };
                            }
                            try {
                                peerConnection1 = await connectionClient.createConnection({ beforeAnswer: beforeAnswer })
                                window.peerConnection1 = peerConnection1;
                            } catch (error) {
                                //that.titulo.innerHTML = "<p></p>";
                                that.setState({ errorMessage: "Por favor verifica tu conexión para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                                that.statusF()
                                this.setState({ loaderNum: false })
                            }
                        }, 500)
                    }
                })
                .catch((error) => {
                    that.getTiempo()
                    let e = error.toString()
                    that.setState({ errorMessage: "Por favor verifica tu conexión para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                    that.statusF()
                    that.setState({ loaderNum: false })
                }
                )
        }, 500);
    }

    takeFotoFace = () => {
        const that = this;
        let Acreated = null;
        let Aexpire = null;

        console.log("takeFotoFace");

        const localVideo = document.getElementById('video_wrt_environment');
        localVideo.classList.remove("blur_video")

        document.getElementById("btnVideo").disabled = 'true';
        this.setState({ textoResult: "", isStart: false, loading: false, errorToken: false, errorMessage: "", loaderNum: true, arrayResult: [] })
        //this.titulo.className = "animate__animated animate__slideInDown"
        //this.titulo.innerHTML = "<p><b>¡Atención!</b> realiza los siguientes movimientos y gestos</p>";
        let maquina4 = document.getElementById('maquina-escribir4')
        this.maquinaEscribir3("¡Empecemos!", 50, maquina4)

        this.elementAudioHello.play()

        this.getRandom()
        this.setState({ init: true });
        setTimeout(() => {
            try {
                this.setState({ init: false });
                localStorage.setItem("threshold", [])
                localStorage.setItem("stdDev", -1)
                obtenerTokenPDFVideo(this.state.uuid)
                    .then((response) => {
                        if (response.status === 200) {
                            statusData("numero", response.data.payload)
                            let listCodes = response.data.payload.code;
                            Acreated = response.data.payload.created;
                            Aexpire = response.data.payload.expire;
                            setTimeout(async () => {
                                that.setState({ loaderNum: false })
                                that.setState({ isRecording: true, isComplete: false, conteo: true })

                                const token = listCodes;
                                that.setState({ token: token })

                                const localVideo = document.getElementById('video_wrt_environment');
                                localVideo.autoplay = true;
                                localVideo.muted = true;
                                let intentos = 0
                                that.setState({ init: true, threshold: [], aspectPorcent: [] });
                                var myArray = this.state.arrayMOv
                                //localStorage.setItem("tipoPrueba", JSON.stringify(myArray));
                                let maquina4 = document.getElementById('maquina-escribir4')
                                //that.titulo.className = ""
                                // Si puede o empiece a grabar enviar el video a send videotoken y sino en el wrtc
                                if (myArray[1] === 0) {
                                    that.maquinaEscribir3("Abre tu boca lo más que puedas ", 50, maquina4)
                                    that.setState({ fboca: true });
                                    that.elementAudioMouth.play();

                                } else {
                                    that.maquinaEscribir3("Gira tu rostro completamente a la " + myArray[0], 50, maquina4)
                                    if (myArray[0] === "Izquierda") {
                                        console.log(that.elementAudioLeft);
                                        that.elementAudioLeft.play()
                                        that.setState({ fizq: true });
                                    } else {
                                        console.log(that.elementAudioRight);
                                        that.elementAudioRight.play()
                                        that.setState({ fder: true });
                                    }
                                }
                                that.recorder.startRecording();
                                console.log("empece a grabar con startRecording");
                                let interval = setInterval(() => {
                                    intentos++;
                                    if (intentos === 5) {// SEG 2.5
                                        if (myArray[1] === 0) {
                                            that.maquinaEscribir3("Cierra tu boca", 50, maquina4)
                                            that.setState({ fbocaCerrada: true, fboca: false });
                                            that.elementAudioClose.play()
                                        }
                                    }
                                    if (intentos === 8) { // SEG 4
                                        that.maquinaEscribir3("¡Gracias!", 50, maquina4)
                                        that.elementAudioThanks.play()
                                    }
                                    if (intentos === 10) { // SEG 5
                                        that.setState({ fizq: false, fder: false, fboca: false, fbocaCerrada: false });
                                        if (myArray[1] === 0) {
                                            that.maquinaEscribir3("Gira tu rostro completamente a la " + myArray[0], 50, maquina4)
                                            if (myArray[0] === "Izquierda") {
                                                console.log(that.elementAudioLeft);
                                                that.elementAudioLeft.play()
                                                that.setState({ fizq: true });
                                            } else {
                                                console.log(that.elementAudioRight);
                                                that.elementAudioRight.play()
                                                that.setState({ fder: true });
                                            }
                                        } else {
                                            that.elementAudioMouth.play();
                                            //that.titulo.className = " maquina"
                                            that.maquinaEscribir3("Abre tu boca lo más que puedas", 50, maquina4)
                                            that.setState({ fboca: true });

                                        }
                                    }
                                    if (intentos === 15) { // SEG 7.5
                                        if (myArray[1] !== 0) {
                                            that.maquinaEscribir3("Cierra tu boca", 50, maquina4)
                                            that.setState({ fbocaCerrada: true, fboca: false });
                                            that.elementAudioClose.play()
                                        }
                                    }
                                    if (intentos === 18) { // SEG 9
                                        that.setState({ init: false });
                                        that.setState({ estable: true })
                                        that.setState({ isRecording: false });
                                        that.setState({ loading: false, hasResponse: false });
                                        that.setState({ fizq: false, fder: false, fboca: false,fbocaCerrada: false });
                                    }
                                    if (intentos === 19) { // SEG 9.5
                                        that.maquinaEscribir3("¡Gracias!", 50, maquina4)
                                        this.elementAudioThanks.play()
                                        that.setState({ fbocaCerrada: false, fboca: false });
                                    }
                                    if (intentos === 22) { // SEG 11
                                        that.setState({ arrayListo: true, registroInicio: Date.now() })
                                        that.contador();
                                        that.recorder.stopRecording(function () {
                                            statusData("termine de grabar", intentos)
                                        });
                                    }
                                    if (intentos === 26) { //SEg 13
                                        that.sendVideo(Acreated, Aexpire);
                                        that.setState({ estable: false, isComplete: true })
                                        localVideo.classList.add("blur_video")
                                        statusData("Finish video", "Finish video")
                                        clearInterval(interval);
                                        // var blob = that.recorder.getBlob();
                                        // that.downloadVideo(that.recorder.getBlob()); 
                                        // that.downloadVideo(blob);
                                    }
                                }, 500);
                            }, 500)
                        }
                    })
                    .catch((error) => {
                        let e = error.toString()
                        statusData("numero", e)
                        that.setState({ errorMessage: "Por favor verifica tu conexión para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                        that.statusF()
                        this.setState({ loaderNum: false })
                    }
                    )

            } catch (error) {
                //console.log("error", error);
                statusData("numero", error)
                that.setState({ errorMessage: "Lo sentimos tu navegador actual no esta actualizado, vuelve a intentar", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: that.state.intents + 1, hasResponse: true })
                that.setState({ errorCapture: true });
                localVideo.classList.remove("blur_video");
            }
        }, 800);

    }

    sendVideo = (Acreated, Aexpire) => {
        //console.log("sendVideo");
        let blob = this.recorder.getBlob();
        
        const localVideo = document.getElementById('video_wrt_environment');
        setTimeout(() => {
            let listaDeMovimientos = []
            if (this.state.arrayMOv[1] === 0) {
                listaDeMovimientos = [
                    {
                        "inicio": 1,
                        "fin": 5,
                        "elementoIdentificar": "Boca",
                        "direccion": "apertura",
                        "movimientoCara": []
                    },/*{
                        "inicio" : 5,
                        "fin" : 9,
                        "elementoIdentificar" : "Cara",
                        "direccion" : (that.state.arrayMOv[0]=== "Izquierda") ? "izquierda" : "derecha",
                        "movimientoCara":[]
                    },*/ {
                        "inicio": 5,
                        "fin": 10,
                        "elementoIdentificar": "Cara",
                        "direccion": (this.state.arrayMOv[0] === "Izquierda") ? "izquierda" : "derecha",
                        "movimientoCara": []
                    }
                ]
            } else {
                listaDeMovimientos = [
                    {
                        "inicio": 1,
                        "fin": 5,
                        "elementoIdentificar": "Cara",
                        "direccion": (this.state.arrayMOv[0] === "Izquierda") ? "izquierda" : "derecha",
                        "movimientoCara": []
                    }, /*{
                        "inicio" : 5,
                        "fin" : 9,
                        "elementoIdentificar" : "Cara",
                        "direccion" : (that.state.arrayMOv[1]=== "Izquierda") ? "izquierda" : "derecha",
                        "movimientoCara":[]
                    },*/{
                        "inicio": 5,
                        "fin": 10,
                        "elementoIdentificar": "Boca",
                        "direccion": "apertura",
                        "movimientoCara": []
                    }
                ]
            }

            let dataJSON = JSON.stringify(listaDeMovimientos);
            console.log("dataJSON:", dataJSON);
            enviarVideoRostro(blob, Acreated, Aexpire, isIOS, dataJSON)
                .then((responseWRT) => {
                    this.getTiempo()
                    //console.log("responsePAYLOAD", responseWRT.data.payload);
                    statusData("responseWRT", responseWRT.data.payload)
                    if (responseWRT.status === 200) {
                        this.setState({ loading: false })
                        //console.log("Entre a 200 ");
                        let msj = responseWRT.data.payload.message
                        if (msj === "OK" || msj === "Ok") {
                            let liveness = responseWRT.data.payload.liveness
                            if (liveness === "Vivo") {
                                this.setLocalStorageResultFace(responseWRT.data.payload)
                                statusData("responseWRT", "si mov")
                                setTimeout(() => {
                                    this.props.history.push("/finalizado")
                                }, 200);

                            } else {
                                this.setLocalStorageResultFace(responseWRT.data.payload)
                                statusData("responseWRT", "spoof back")
                                setTimeout(() => {
                                    this.props.history.push("/finalizado")
                                }, 200);
                            }
                            this.setState({ loading: false, intents: 0, hasResponse: true })
                        } else {
                            this.setState({ errorMessage: msj, errorMessage2: "", errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                            this.setState({ errorCapture: true });
                            localVideo.classList.remove("blur_video");
                        }
                    } else {
                        this.getTiempo()
                        this.setState({ errorMessage: msjError, errorToken: true, errorF: "Asegúrate de colocar tu rostro dentro del área indicada, con buena luz y enfocada.", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        this.statusF()
                    }
                })
                .catch((error) => {
                    this.getTiempo()
                    if (error.response) {
                        statusData("responseERROR", error.response)
                        let mensaje = ""
                        if (error.response.data) {
                            mensaje = error.response.data.message_client
                        }
                        let eMessage = msjError
                        if (error.response.status === 500 || error.response.status === 400) {
                            eMessage = this.msjsError(mensaje);
                            this.setState({ errorMessage: eMessage, errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        } else if (error.response.status === 404) {
                            this.setState({ errorMessage: msjError, errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        } else {
                            this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                        }
                    }

                    this.setState({ errorCapture: true });
                    localVideo.classList.remove("blur_video");
                })
        }, 400);
    }

    downloadVideo = (blob) => {
        var video = document.createElement('video');
        video.src = URL.createObjectURL(blob);
        //console.log("time1", video.duration);
        video.onloadedmetadata = function (e) {
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "test.webm";
            a.click();
            window.URL.revokeObjectURL(url);
            //console.log("time2", video.duration);
        }
    }

    downloadAudio = (blob) => {
        var video = document.createElement('audio');
        video.src = URL.createObjectURL(blob);
        //console.log("time1", video.duration);
        video.onloadedmetadata = function (e) {
            var url = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "test.wav";
            a.click();
            window.URL.revokeObjectURL(url);
            //console.log("time2", video.duration);
        }
    }

    getTiempo = () => {
        let registroFin = Date.now()
        let milliseconds = (registroFin - this.state.registroInicio)
        let segundos = (milliseconds / 1000) % 60
        //console.log("segundos", segundos);
        sendTime(segundos)
    }

    setLocalStorageResultFace = (result) => {
        let face = {}
        //console.log("ServerResponse: ", result);
        if (result) {
            face = {
                match: result.match,
                liveness: result.liveness,
                faceComparison: result.faceComparison,
                message: result.message,
                highest_class_un_frame: result.highest_class_un_frame,
                highest_class: result.highest_class,
                movimientoBoca: result.movimientoBoca
            }
        } else {
            face = {
                match: false,
                faceComparison: {},
                liveness: "No superada",
                message: "FRONT",
                highest_class_un_frame: 0,
                highest_class: 0,
                movimientoBoca: false
            };
        }//liveness
        localStorage.setItem("face", JSON.stringify(face));
    };

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {////console.log("adios 🐶");
        });
    }

    statusE = () => {
        setTimeout(() => {
            window.close()
        }, 300);
    }

    statusF = () => {
        ////console.log("entre a status ine");
        statusError("videotoken", this.state.errorF, "error")
    }

    checkCamera = () => {
        setTimeout(() => {
            ////console.log("Camera:"+this.state.streamStarted);
            if (!this.state.streamStarted) {
                this.setState({ errorVideo: true })
            }
        }, 3500);
    }

    maquinaEscribir3 = (text = '', tiempo = 200, etiqueta = '') => {
        if (this.state.streamStarted) {
            let arrayCaracteres = text.split('')
            etiqueta.innerHTML = ''
            let i = 0
            let j = text.length
            let escribir = setInterval(() => {
                if (i === arrayCaracteres.length) {
                    clearInterval(escribir);
                    console.log("limpie interval");
                    return
                    /*
                    etiqueta.innerHTML = text.substring(0, j)
                    j--
                    if (j === 0) {
                        console.log("termine");
                        etiqueta.innerHTML = ''
                        i = 0
                        j = text.length
                    }
                    */
                } else {
                    console.log("arrayCaracteres");
                    etiqueta.innerHTML += arrayCaracteres[i]
                    i++
                    if(arrayCaracteres.length > 15){
                        document.getElementById("maquina-escribir4").style.marginLeft = "-" + 5*i + "px"
                    }else{
                        document.getElementById("maquina-escribir4").style.marginLeft = "-" + 7*i + "px"
                    }
                    console.log("Margen:", document.getElementById("maquina-escribir4").style.marginLeft);
                }
            }, tiempo)
        }
    }


    render() {
        const { isLandscape } = this.props;
        return (
            <div>

                {(this.state.errorToken) ?
                    <div className="modal fade show" style={{ display: "block" }} role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Captura nuevamente</h5>
                                    <Link to={"/" + localStorage.getItem("apikeyOtorgante")} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </Link>
                                </div>
                                <div className="modal-body">
                                    <p><b>{this.state.errorMessage}</b></p>
                                    <p><b>{this.state.uuid.split("-")[0]}</b></p>
                                </div>
                                <div className="modal-footer">
                                    <Link to={"/" + localStorage.getItem("apikeyOtorgante")} className="btn btn-secondary" data-dismiss="modal">Cancelar</Link> 
                                    
                                    {/* <button type="button" id="btnVideo" onClick={isIOS ? this.takepictureIOS : this.takeFotoFace} className="btn btn-primary btn-success">Reintentar</button> */}
                                    <button type="button" id="btnVideo" onClick={this.state.suportMediaRecorder ? this.takeFotoFace : this.takepictureIOS} className="btn btn-primary btn-success">Reintentar</button>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                <div className="module_container overflow_hddn">
                    <div className="camera_capture_frame speech_mod">
                        <video id="video_wrt_environment" playsInline muted></video>
                        <canvas id="canvas_wrt_environment" hidden></canvas>
                    </div>
                    <div className="module_gradident_overlay"></div>

                    <img className="id_mask animate__animated animate__fadeIn animate__delay-2s" src={process.env.PUBLIC_URL + '/images/face_id_on.svg'} alt="" />
                    <div className="align_alert hide_elmnt"><p>Mantén tu rostro dentro del área definida para iniciar la lectura.</p>
                    </div>

                    <div className="recorder_container">
                        {(this.state.isComplete) ?
                            <div className="recorder_box">
                                <div className="circle_back" >
                                    <div className="circle_front"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul>
                                        <li className="b_a"></li>
                                        <li className="b_b"></li>
                                        <li className="b_c"></li>
                                        <li className="b_d"></li>
                                        <li className="b_e"></li>
                                        <li className="b_f"></li>
                                        <li className="b_g"></li>
                                        <li className="b_h"></li>
                                        <li className="b_i"></li>
                                        <li className="b_j"></li>
                                        <li className="b_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div> : ("")}

                        {(this.state.isRecording) ? <div>
                            <div className="recorder_box">
                                <div className="circle_back recording_state" onClick={this.stopRecord}>
                                    <img className="hide_elmnt" src={process.env.PUBLIC_URL + '/images/warning_36.png'} width="35" alt="" />
                                    <div className="circle_front animate__animated animate__infinite animate__pulse"></div>
                                </div>
                                <div className="audio_meter_box">
                                    <ul className="rec_state">
                                        <li className="b_a"></li>
                                        <li className="b_b anim_b"></li>
                                        <li className="b_c anim_c"></li>
                                        <li className="b_d anim_d"></li>
                                        <li className="b_e anim_e"></li>
                                        <li className="b_f anim_f"></li>
                                        <li className="b_g anim_g"></li>
                                        <li className="b_h anim_h"></li>
                                        <li className="b_i anim_i"></li>
                                        <li className="b_j anim_j"></li>
                                        <li className="b_k anim_k"></li>
                                        <li className="b_l"></li>
                                    </ul>
                                </div>
                            </div>
                        </div> : ("")}
                    </div>
                    {(this.state.isRecording) ? <div className="status_txt">
                        <p>Escuchando</p>
                    </div> : ("")}
                </div>

                {(this.state.isStart) ? <Fragment> <div className="overlay_box">
                    <div className="alert_box">
                        <p className="animate__animated animate__slideInDown">Por favor <b>mantén tu rostro visible</b> dentro de la guía, <b>evita contraluz,</b> sin lentes, gorra, ni más personas en la toma.</p>
                        {/* <button type="button" id="btnVideo" onClick={isIOS ? this.takepictureIOS : this.takeFotoFace} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-1s">SIGUIENTE</button> */}
                        {/* <button type="button" id="btnVideo" onClick={isIOS ? this.takeFotoIOS : this.takeFotoFace} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-1s">SIGUIENTE</button> */}
                        <button type="button" id="btnVideo" onClick={this.state.suportMediaRecorder ? this.takeFotoFace : this.takepictureIOS} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-1s">SIGUIENTE</button>
                    </div>
                </div></Fragment> : ("")}

                <div className="caja">
                    <p id="maquina-escribir4">
                    </p>
                </div>
                <div id="cuadro" className="animate__animated">

                    {(this.state.fizq) ?
                        <div className="imgCont ">
                            <div className="hijo">
                                <img className="bottom" src="/images/faces/left_face.svg"></img>
                                <img className="middle" src="/images/faces/left_a_face.svg"></img>
                                <img className="top" src="/images/faces/front_face.svg"></img>
                            </div>
                        </div>
                        :
                        ("")
                    }
                    {(this.state.fder) ?
                        <div className="imgCont ">
                            <div className="hijo">
                                <img className="bottom" src="/images/faces/right_face.svg"></img>
                                <img className="middle" src="/images/faces/right_a_face.svg"></img>
                                <img className="top" src="/images/faces/front_face.svg"></img>
                            </div>
                        </div>
                        :
                        ("")
                    }
                    {(this.state.fboca) ?
                        <div className="imgCont ">
                            <div className="hijo">
                                <img className="" src="/images/faces/boca_face.svg"></img>
                            </div>
                        </div>
                        :
                        ("")
                    }
                    {(this.state.fbocaCerrada) ?
                        <div className="imgCont ">
                            <div className="hijo">
                                <img className="" src="/images/faces/front_face.svg"></img>
                            </div>
                        </div>
                        :
                        ("")
                    }
                </div>

                {/*<audio id="audio" className="audio" controls>   
                    <source type="audio/mp3" src={this.state.audio} />
                </audio>*/}
                <div id="divAudios">
                    <audio id="audioHello" className="audioMP3" controls>
                        <source type="audio/mpeg" src="https://dco-bucket-assets-prod.obs.na-mexico-1.myhuaweicloud.com/media/empecemos.mp3" />
                    </audio>
                    <audio id="audioThanks" className="audioMP3" controls>
                        <source type="audio/mpeg" src="https://dco-bucket-assets-prod.obs.na-mexico-1.myhuaweicloud.com/media/gracias.mp3" />
                    </audio>
                    <audio id="audioClose" className="audioMP3" controls>
                        <source type="audio/mpeg" src="https://dco-bucket-assets-prod.obs.na-mexico-1.myhuaweicloud.com/media/boca_cierra.mp3" />
                    </audio>
                    <audio id="audioMouth" className="audioMP3" controls>
                        <source type="audio/mpeg" src="https://dco-bucket-assets-prod.obs.na-mexico-1.myhuaweicloud.com/media/boca_mas.mp3" />
                    </audio>
                    <audio id="audioLeft" className="audioMP3" controls>
                        <source type="audio/mpeg" src="https://dco-bucket-assets-prod.obs.na-mexico-1.myhuaweicloud.com/media/izquierda.mp3" />
                    </audio>
                    <audio id="audioRight" className="audioMP3" controls>
                        <source type="audio/mpeg" src="https://dco-bucket-assets-prod.obs.na-mexico-1.myhuaweicloud.com/media/derecha.mp3" />
                    </audio>
                </div>

                <button id="play" hidden>Play</button>

                {(this.state.estable) ? <div className="sprite_stay_a animado "></div> : ("")}
                {(this.state.loaderNum) ? <Loader /> : ("")}
                {(this.state.isComplete && !this.state.hasResponse) ? <LoaderSpoof /> : ("")}
                {
                    (isLandscape) ? (
                        <div className="rotate_device">
                            <div className="center_info_bx">
                                <img src="images/rotate_device.svg" height="100" alt="" />
                                <p>Por favor usa tu dispositivo en vertical<br />
                                    <small>Gira tu dispositivo para continuar</small>
                                </p>
                            </div>
                        </div>) : ("")
                }
                {(this.state.errorVideo) ?
                    <div className="overlay_box">
                        <div className="alert_box">
                            <p className="animate__animated animate__slideInDown">Hemos detectado que la <b>cámara de tu dispositivo</b> está siendo usada por otra web en <b>alguna pestaña</b>, por favor asegúrate de <b>cerrar las pestañas</b> abiertas e inténtalo nuevamente.</p>
                            <button type="button" onClick={(e) => {
                                this.setState({ errorVideo: false })
                                window.location.reload()
                                //this.props.history.push("/identificacion")
                            }} className="btn btn-raised btn-primary forcewidth100 main_bg_color alert_btn  animate__animated animate__fadeIn animate__delay-2s">VOLVER A INTENTAR</button>
                        </div>
                    </div>
                    :
                    ""
                }

            </div>
        );
    }
}
CameraVideoTokenIOS = withOrientationChange(CameraVideoTokenIOS);
export default withRouter(CameraVideoTokenIOS);