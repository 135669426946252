import React, { useState } from "react";
import { useEffect } from "react";

const Base = () => {

    
    return (
        <div>
    
        </div>
    
    
    )
}



export default Base
