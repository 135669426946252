import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import { isMobile } from 'react-device-detect';

const Inicio = () => {
    const [hayCookies] = useState(navigator.cookieEnabled);

    const reload = () => {
        window.location.reload();
    }

    return (
        <div>
            <div className="main_gradient">
                <Header />
                <div className="main_text_container text-left ">
                    <h1 className="animate__animated text-left">{(localStorage.getItem("apikeyOtorgante") === "tmIerl5JiLpbbx0ntRBC7cjoBeDobQep") ? "Video Token" : "Liveness check"}</h1>
                    <p>Por favor mantén tu <b>rostro visible</b> dentro de la <b>guía, sin personas</b> en la toma y <b>asegúrate</b> de lo siguiente:<br /></p>
                    <br></br>
                    <div className="items_video_token">
                        <table>
                            <tbody>
                                <tr>
                                    <td><img src="images/bnw/selfie_no_glasses.svg" height="64" alt="" /></td>
                                    <td>No utilices lentes oscuros.</td>
                                </tr>
                                <tr className="espacio"></tr>
                                <tr>
                                    <td><img src="images/bnw/selfie_no_caps.svg" height="64" alt="" /></td>
                                    <td>No utilices gorras.</td>
                                </tr>
                                <tr className="espacio"></tr>
                                <tr>
                                    <td><img src="images/bnw/selfie_no_face_mask.svg" height="64" alt="" /></td>
                                    <td>Retira tu cubreboca.</td>
                                </tr>
                                <tr className="espacio"></tr>
                                <tr>
                                    <td><img src="images/bnw/selfie_no_back_light.svg" height="64" alt="" /></td>
                                    <td>Evita colocarte a contraluz.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={isMobile ? "action_buttons" : "action_buttons_desk"}>
                    {/*
                    <form>
                    <div className="form-group bmd-form-group text-white ">
                    <label htmlFor="tipo">Elige tipo de prueba:</label>
                    <select className="custom-select" id="tipo"  onChange={e => setTipo(e.target.value)} >
                        <option value="real">Real</option>
                        <option value="2d-photo">2D Photo</option>
                        <option value="2d-mask">2D Mask</option>
                        <option value="2d-mask-curve">2D Mask Curve</option>
                        <option value="video-on-laptop">Video On Laptop</option>
                        <option value="3d-layer">3D Layer</option>
                        <option value="phone-on-phone">Phone to Phone</option>
                    </select>
                    </div>
                    
                    </form>
                    */}
                    <Link to="videotoken" className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-1s">
                        EMPEZAR</Link>
                </div>

                <Footer />
                {
                    (!hayCookies) ? (<div className="err_finale  animate__animated animate__slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu navegador</h5>
                            <p>Para acceder a este sitio es necesario habilitar el uso de Cookies
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                            </div>
                        </div>
                    </div>) : ("")}
            </div>

        </div>
    )
}

export default Inicio
