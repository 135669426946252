import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Inicio from './pages/inicio'
import Videotoken from './pages/videotoken'
//import Videotoken from './pages/videotoken_rtc'
import VideotokenM from './pages/videotoken_movimiento'
//import Videotoken from './pages/videotoken_ambiente'
import Finalizado from './pages/finalizado'
import Splash from './pages/splash'
import Base from './pages/base'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/index">
          <Inicio />
        </Route>
        <Route path="/videotoken">
          <Videotoken />
        </Route>
        <Route path="/movimiento">
          <VideotokenM />
        </Route>
        <Route path="/finalizado">
          <Finalizado />
        </Route>
        <Route path="/inicio">
          <Inicio />
        </Route>
        <Route path="/base">
          <Base />
        </Route>
        <Route path="/:apikey">
          <Splash />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
