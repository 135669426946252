import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Header from "../components/header";
import { obtenerParametrosOtorgante, obtenerUuidOtorgante, initStatus } from '../services/api.js';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import { v4 as uuidv4 } from 'uuid';

const Splash = () => {
    const { apikey } = useParams();
    const history = useHistory();
    const [apiKey, setApiKey] = useState('')
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [show, setShow] = useState(false)
    const [isCorrectApikey, setCorrectApikey] = useState(true);


    useEffect(() => {
        localStorage.clear();
        if (hayCookies) {
            let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
            if (apikeyOtorgante !== null || apikeyOtorgante !== undefined) {
                setApiKey(apikeyOtorgante)
            } else {
                setApiKey(apikey)
            }
            localStorage.setItem("apikeyOtorgante", apikey)

            if (new URL(window.location).searchParams.get("transaccion")) {
                localStorage.setItem("uuidTrx", new URL(window.location).searchParams.get("transaccion"))
                obtenerUuid()
            } else {
                let uuidUser = localStorage.getItem("uuidUser");
                if (uuidUser === null || uuidUser !== undefined) {
                    if (new URL(window.location).searchParams.get("cliente")) {
                        localStorage.setItem("uuidUser", new URL(window.location).searchParams.get("cliente"))
                    } else {
                        let newUuid = uuidv4();
                        localStorage.setItem("uuidUser", newUuid);
                    }
                }
                let id_t = localStorage.getItem("uuidTrx")
                if (id_t === null) {
                    id_t = uuidv4();
                    localStorage.setItem("uuidTrx", id_t)
                }
            }
        }
    }, [apikey])

    useEffect(() => {
        obtenerParametros()
        initStatus()
    }, [])

    useEffect(() => {
        //console.log("actualizando dataOtorgante:",dataOtorgante);
        setDataOtorgante(dataOtorgante)
        configStyle(dataOtorgante)
        if (hayCookies) {
            localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
        }
        let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
        //console.log("logo", logo);
        document.getElementById("imagenLogo").src = logo;
    }, [dataOtorgante])

    const obtenerParametros = async () => {
        //setLoading(true)
        obtenerParametrosOtorgante(apikey, apiKey).then((data) => {
            if (data.status === 200) {
                setDataOtorgante(data.data.payload)
            }
            //setLoading(false)
        }).catch((e) => {
            console.log("Error: "+e);
            setCorrectApikey(false);
        })
    }

    const obtenerUuid = async () => {
        //setLoading(true)
        obtenerUuidOtorgante().then((response) => {
            if (response.status === 200) {
                //console.log("Hay uuid");
                localStorage.setItem("uuidUser", response.data.payload.uuidUser)
                localStorage.setItem("uuidOtorgante", response.data.payload.otorgante)
            }
        }).catch((e) => {
            console.log("Error: "+e);
            setCorrectApikey(false);
        })
    }

    const aceptarTerminos = check => {
        if (check) {
            setShow(true)
        }else{
            setShow(false)
        }
    };

    const goVideotoken = check => {
        setTimeout(() => {
            history.push("/inicio" )
        }, 300);
    };

    const reload = () => {
        window.location.reload();
    }
    
    return (
        <div>
            <div className="main_gradient">
                <Header ruta={apiKey} />
                <div className="main_text_container text-left ">
                    <h1 className="animate__animated text-left">Bienvenido</h1>
                    <p>Te invitamos a vivir nuestra tecnología Liveness Check.</p>
                    <div className="">
                        <center><img src="images/welcome_icon_a.svg" className="img_face"></img></center>
                    </div>
                    <div className="check_container">
                        <form>
                            <div className="checkbox">
                                <label htmlFor="check_terms">
                                    <input id="check_terms" type="checkbox" onChange={e => aceptarTerminos(e.target.checked)} />
                                    <span className="checkbox-decorator"><span className="check"></span></span>
                                    He leído y acepto el <a href="https://dicio.com/Notice.html"><b>Aviso de privacidad</b></a>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="action_buttons noscroll_screen">
                        <button onClick={e => goVideotoken()} disabled={!show} className="btn btn-raised btn-primary forcewidth100 main_bg_color">
                        EMPEZAR</button>
                </div>
            </div>
            {
                    (!isCorrectApikey) ? (<div className="err_finale animate__animated animate__slideInUp">
                        <div className="center_checks">
                            <h5>Verifica tu URL</h5>
                            <p>Lo sentimos la dirección que buscas no se encuentra o es incorrecta, por favor verifica la información e intenta nuevamente.
                            </p>
                            <br />
                            <div className="action_buttons noscroll_screen">
                                <button type="button" onClick={() => reload()} className="btn btn-raised btn-primary forcewidth100 main_bg_color">REINTENTAR</button>
                            </div>
                        </div>
                    </div>) : ("")}
        </div>
    
    
    )
}



export default Splash
