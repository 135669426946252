import React, { useState, useEffect, Fragment } from "react";

const LoaderSpoof = (props) => {

    const [stateActionA, setStateActionA] = useState(true);
    const [stateActionB, setStateActionB] = useState(true);
    const [stateActionC, setStateActionC] = useState(true);
    const [stateActionD, setStateActionD] = useState(true);
    const [stateActionE] = useState(true);
    const [stateWord, setStateWord] = useState(true);
    let tiempo0 = 500;
    let tiempo1 = 1500;
    let tiempo2 = 1000;

    useEffect(() => {
        setTimeout(() => {
            setStateActionA(false);
            setTimeout(() => {
                setStateActionB(false);
                setTimeout(() => {
                    setStateActionC(false);
                    setTimeout(() => {
                        setStateActionD(false);
                        setTimeout(() => {
                            //setStateActionE(false);
                        }, 1000)
                    }, 1000)
                }, tiempo2)
            }, tiempo1)
        }, tiempo0)
    }, [])

    return (
        <Fragment>
            <div className="id_progress  animate__animated animate__slideInUp">
                <div className="center_checks">
                    <h5>Estamos analizando tu captura, este proceso puede tardar unos segundos.</h5>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_a", ((stateActionA) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Extrayendo información</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_b", ((stateActionB) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img className="" src="/images/check_36.png" width="24" alt="" />
                        <p>Analizando características</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_c", ((stateActionC) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Identificando rostro</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_d", ((stateActionD) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Validación de parametros</p>
                    </div>

                    <div className={["inline_analisis", "animate__animated", "animate__fadeIn", "action_e", ((stateActionE) ? "active_v" : "done_v")].join(" ")}>
                        <div className="inline_loader">
                            <div className="ispinner ispinner-large">
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                                <div className="ispinner-blade"></div>
                            </div>
                        </div>
                        <img src="/images/check_36.png" width="24" alt="" />
                        <p>Procesando resultados</p>
                    </div>

                </div>
            </div>
        </Fragment>
    );
}

export default LoaderSpoof;