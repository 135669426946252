/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import 'video.js/dist/video-js.css';
import 'webrtc-adapter';
import { configStyle, obtenerValorConfig } from '../services/configStyle';
import Loader from "../components/loader";
import { v4 as uuidv4 } from 'uuid';
import { obtenerTokenPDFVideo, enviarVideoRostroToken } from '../services/api';
import { isIOS, isMobileSafari, isFirefox, isMobile, isIPad13, withOrientationChange, isSafari } from 'react-device-detect';

import * as faceapi from '@vladmandic/face-api'


const msjLuz = "Por favor busca una posición con menos luz directa en tu rostro al momento de la captura."
const msjObscuridad = "Por favor busca un lugar con más luz al momento de la captura."
const msjCubrebocas = "Parece que algún objeto obstruye tu rostro, por favor retíralo y captura nuevamente."
const msjRostro = "No identificamos tu rostro, por favor centra tu rostro en el área marcada."
const msjMultiple = "Parece que hay más de un rostro en la toma, asegúrate que no haya nadie mas al momento de la captura."
const msjBorrosa = "Por favor asegurate que tu cámara esta limpia y mantén firme tu dispositivo para evitar distorsiones en la imagen."
const msjFrase = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjFrase2 = "No logramos entender la frase, por favor lee FUERTE y CLARO e intenta nuevamente."
const msjDistancia = "Por favor asegúrate de centrar tu rostro en el área marcada y captura nuevamente."
const msjAudio = "No logramos capturar audio en tu captura, asegúrate que tu micrófono funciona correctamente e intenta nuevamente."
const msjError = "Lo sentimos no hemos logrado validar tu captura, por favor sigue las instrucciones e intenta nuevamente"

class CameraVideoTokenIOS extends Component {

    constructor(props) {
        super(props);
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    state = {
        loading: false,
        token: null,
        isRecording: false,
        isComplete: false,
        isStart: true,
        uuid: null,
        errorToken: false,
        dataUser: [],
        dataOtorgante: [],
        codes: [],
        face: {},
        errorMessage: "",
        errorMessage2: "",
        intents: 0,
        apikey: "",
        hasResponse: false,
        conteo: false,
        codec: "video/webm;codecs=vp8",
        errorF: "",
        errorVideo: false,
        streamStarted: false,
        pathname: "",
        loaderNum: false,
        timeInit: 0,
        arrayResult: [],
        modelReady: null,
        listoModelo: false,
        textoResult: "",
        real: 0,
        spoof: 0,
        stdDevVal: 0,
        modelframes: null,
        ModelURL: process.env.PUBLIC_URL + "/models",
        threshold: [],
        aspectPorcent: [],
        msjJSONError: null,
        tamW: 0,
        tanH: 0,
        device: null
    }

    componentDidMount() {

        if (isFirefox && !isMobile) {
            this.state.codec = "video/webm;codecs=vp9"
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            this.setState({ apikey: apikeyOtorgante })
        }

        let codesLS = localStorage.getItem("codes")
        if (codesLS !== null) {
            this.setState({ codes: JSON.parse(codesLS) });
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            this.setState({ uuid: uuidUser })
        }

        let face = localStorage.getItem("face")
        if (face !== null) {
            let id_t = uuidv4();
            localStorage.setItem("uuidTrx", id_t)
        }

        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            this.setState({ dataUser: JSON.parse(dataUserLS) })
        }

        this.state.pathname = window.location.pathname;

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            let dataOtorganteJSON = JSON.parse(dataOtorganteLS)
            this.setState({ dataOtorgante: JSON.parse(dataOtorganteLS) })
            configStyle(dataOtorganteJSON)
        }

        this.video = document.querySelector('video');

    }

    setLocalStorageResultFace = (result) => {
        let rostro = {}
        console.log("responseWRT: ", result);
        if (result) {
            rostro = {
                match: result.match,
                liveness: result.liveness,
                faceComparison: result.faceComparison,
                message: result.message,
                clasificacion_un_frame: result.clasificacion_un_frame,
                movimientoBoca: result.movimientoBoca
            }
        console.log("rostro: ", rostro);
        } else {
            rostro = {
                match: false,
                faceComparison: {},
                liveness: "No superada",
                message: "FRONT",
                clasificacion_un_frame: "",
                movimientoBoca: false
            };
        console.log("rostro: ", rostro);

        }//liveness
        localStorage.setItem("face", JSON.stringify(rostro));
        this.setState({ face: rostro })
    };

    // destroy player on unmount
    componentWillUnmount() {
        if (this.localStream) {
            this.localStream.then(function (mediaStream) {
                mediaStream.getTracks().forEach(track => track.stop());
            });
            this.localStream = null;
        }
        window.removeEventListener("beforeunload", () => {//console.log("adios 🐶");
        });
    }

    statusE = () => {
        setTimeout(() => {
            window.close()
        }, 300);
    }

    statusF = () => {
        //console.log("entre a status ine");
        //statusError("Captura Video Token", this.state.errorF, "error")
    }

    playlocalVID = () => {
        console.log("entre");
        this.setState({errorMessage:""})
        var player = document.getElementById("videoPlayer");
        var currentVID = document.getElementById("currentVID");
        var selectedLocalVID = document.getElementById("newlocalFILE").files[0];
        console.log("selectedLocalVID", selectedLocalVID);
        if (selectedLocalVID !== undefined) {
            currentVID.setAttribute("src", URL.createObjectURL(selectedLocalVID));
            console.log("ya cagrgue video");
            player.load();
        }

        //player.play();
    }

    startVideo = async () => {
        console.log('Video has startes')
        this.setState({ open: 0, close: 0, stdDevVal: 0, errorToken: false, aspectPorcent: [], face: {}, threshold: [] })
        localStorage.setItem("threshold", JSON.stringify(this.state.threshold))
        //this.setState({ modelframes: window.requestAnimationFrame(this.loop) })
        this.setState({ tamW: this.video.videoWidth, tanH: this.video.videoHeight })
        var listaImg = []
        var intentos = 0
    }

    endVideo = () => {
            obtenerTokenPDFVideo(this.state.uuid)
                .then((response) => {
                    if (response.status === 200) {
                        console.log("response");
                        let listCodes = response.data.payload.code;//.map((elemento, indice) => { return { used: false, token: elemento, expire: new Date().getTime(), created: new Date().getTime() } });
                        let Acreated = response.data.payload.created;
                        let Aexpire = response.data.payload.expire;

                        setTimeout(() => {
                            console.log("Entre al time out");
                            var selectedLocalVID = document.getElementById("newlocalFILE").files[0];
                            console.log("Cargué el video");
                            enviarVideoRostroToken(selectedLocalVID, Acreated, Aexpire, isIOS, listCodes)
                                .then((responseWRT) => {
                                    this.setState({ loaderNum: false })
                                    console.log("RESPONSE", responseWRT);
                                    this.setLocalStorageResultFace(responseWRT.data.payload)
                                    this.setState({ loading: false, intents: 0, hasResponse: true })
                                })
                                .catch((error) => {
                                    //console.log("responseERROR", error.response)
                                    this.setState({ loaderNum: false, msjJSONError: error.response.data.message_client})
                                    let mensaje = error.response.data.message_client
                                    let eMessage = "Asegúrate de colocar tu rostro y pronunciar correctamente."
                                    if (error.response.status === 500 || error.response.status === 400) {
                                        if (mensaje === "No se encontro rostro") {
                                            eMessage = msjRostro
                                        } else if (mensaje === "Se detecto cubrebocas") {
                                            eMessage = msjCubrebocas
                                        } else if (mensaje === "Condiciones de luz no adecuadas") {
                                            eMessage = msjLuz
                                        } else if (mensaje === "Imagen borrosa") {
                                            eMessage = msjBorrosa
                                        } else if (mensaje === "Se detectaron multiples caras") {
                                            eMessage = msjMultiple
                                        } else if (mensaje === "No logramos entender la frase, intente nuevamente") {
                                            eMessage = msjFrase
                                        } else if (mensaje === "Falta de luz") {
                                            eMessage = msjObscuridad
                                        } else if (mensaje === "Posicion de cara incorrecta") {
                                            eMessage = msjDistancia
                                        } else if (mensaje === "Error frase") {
                                            eMessage = msjFrase2
                                        }
                                        this.setState({ errorMessage: eMessage, errorToken: true, errorF: "Asegúrate de colocar tu rostro y pronunciar correctamente.", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                                    } else if (error.response.status === 404) {
                                        this.setState({ errorMessage: "No se detecto audio, por favor no cubrir el micrófono e intentar nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                                    } else {
                                        this.setState({ errorMessage: "Revisa tu conexión a internet e intenta nuevamente", errorToken: true, errorF: "Revisa tu conexión a internet e intenta nuevamente", token: null, loading: false, intents: this.state.intents + 1, hasResponse: true })
                                    }
                                    this.setState({ errorCapture: true });
                                })
                        }, 500);

                    }
                })
                .catch((error) => {
                    let e = error.toString()
                    this.setState({ errorMessage: "Por favor verifica tu conexión para continuar.", errorToken: true, errorF: "Error en la captura de rostro", token: null, loading: false, hasResponse: true })
                    this.statusF()
                    this.setState({ loaderNum: false })
                });
        console.log('Video has ended')
        this.setState({ loaderNum: true })
    }

    setTam = () => {
        console.log("entre");
    }


    render() {
        const { isLandscape } = this.props;
        return (
            <div>
                <div className="main_gradient">
                    <div className="main_text_container text-left ">
                        <h1>Carga de videos</h1>
                        <p>Dar play para empezar a evaluar</p>
                        <input id="newlocalFILE" name="localfile" size="50" maxLength="100000" accept="video/*" type="file" onChange={this.playlocalVID} />
                        <br></br>
                        <div id="contedor">
                            <video controls id="videoPlayer" playsInline onPlay={() => this.startVideo()} onEnded={() => this.endVideo()} onLoad={() => this.setTam()}>
                                <source id="currentVID" src="" />
                            </video>
                        </div>
                        <br></br>
                        {
                            (this.state.errorToken) ?
                                <div><p>{"Error => " + this.state.errorMessage}</p></div> : null
                        }
                        <p><b>{"Landmarks Model: "} </b><b>{(this.state.errorMessage) ? this.state.msjJSONError : JSON.stringify(this.state.face.movimientoBoca)}</b></p>
                        <p><b>{"Resultados Modelos:" + this.state.face.liveness}</b></p>
                        <p><b>{"Modelo:" + this.state.face.clasificacion_un_frame}</b></p>
                        {this.state.device === "ios" ?
                                <p><b>{(this.state.face.liveness === "Vivo") ? "Resultado Final: REAL" : "Resultado Final: SPOOF"}</b></p>
                                :
                                "" 
                        }

                    </div>
                    {(this.state.loaderNum) ? <Loader /> : ("")}
                </div>

            </div>
        );
    }
}
CameraVideoTokenIOS = withOrientationChange(CameraVideoTokenIOS);
export default withRouter(CameraVideoTokenIOS);