import axios from 'axios';
import {
    v4 as uuidv4
} from 'uuid';

const host = "https://srvs.proddicio.net:8444";
const servicio = "prod_"

//const host = "https://api.devdicio.net:8444";
//const servicio = "dev_"

const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}params`,
    otorgantePK: `${host}/v1/sec_${servicio}params_pk`,
    pdftoken: `${host}/v1/sec_${servicio}token_numbers_pk`,
    getUU: `${host}/v1/sec_${servicio}get_user_info_video_token`,
    validateVideo: `${host}/v1/sec_${servicio}video_token`,
    validateVideoAndroid: `${host}/v1/sec_${servicio}mov_video_token`,
    validateHorus: `${host}/v1/sec_${servicio}video_token`,
    files: `${host}/v1/sec_${servicio}file`,
    actualizarEstado: `${host}/v1/sec_${servicio}update_cycle`,
    actualizarSTD: `${host}/v1/sec_${servicio}update_video_token`,
};

const version = "24.1"
let CancelToken = axios.CancelToken;
let cancel;

export function obtenerParametrosOtorgante(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version
        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerParametrosOtorgantePK(idOtorgante, apikey) {
    return axios({
        method: 'get',
        url: endpoints.otorgantePK,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'app_version': version

        },
        params: {
            "idOtorgante": idOtorgante
        }
    });
}

export function obtenerUuidOtorgante() {
    return axios({
        method: 'post',
        url: endpoints.getUU,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version

        },
        data: {
            "transaccion": localStorage.getItem("uuidTrx"), //uuidTrx
        }
    });
}

export function obtenerTokenVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.token,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version

        },
        crossdomain: true,
        params: {
            uuid: uuid
        }
    });
}

export function obtenerTokenPDFVideo(uuid) {
    return axios({
        method: 'post',
        url: endpoints.pdftoken,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version

        },
        crossdomain: true,
        data: {
            uuid: uuid,
        }
    });
}

export function cancelarRequest() {
    // Cancel request
    console.log("Cancelando...");
    cancel();
}

export function enviarVideoRostro(facefile, Acreated, Aexpire, isIOS, dataJSON ) {
    console.log("isIOS:", isIOS);
    let so = isIOS ? 'ios' : 'android';
    var data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", so);
    data.append("tipo_prueba", localStorage.getItem("tipoPrueba"));
    data.append("lista_de_movimientos", dataJSON);
    data.append("service_call", "videotoken-movimientos");
    //video
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);
    console.log("llene el form:", VIDEO_OUTPUT_FILE);

    return axios({
        method: 'POST',
        //url: endpoints.validateVideo,
        url: endpoints.validateHorus,  //`${host}/v1/sec_${servicio}horus_video_token`,
        cancelToken:  new CancelToken(function executor(cancelar) {
            // An executor function receives a cancel function as a parameter
            cancel = cancelar;
        }),
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version,
            'tipo_prueba': localStorage.getItem("tipoPrueba")

        },
        data: data
    });
    //return requestValidateVideo(dataRequest);
}

export function enviarVideoRostroToken(facefile, Acreated, Aexpire, isIOS, token) {
    console.log("isIOS:", isIOS);
    var data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    //data.append("originOS", (isIOS ? "ios":"android"));
    data.append("originOS", "android");
    //movimiento tipoPrueba
    data.append("tipo_prueba", localStorage.getItem("tipoPrueba"));
    data.append("standard_deviation", localStorage.getItem("stdDev"));

    data.append("service_call", "videotoken-only-text");
    data.append("token_code", token);
    //video
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    if (isIOS) {
        VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.mp4';
    }
    data.append("videotoken", facefile, VIDEO_OUTPUT_FILE);
    console.log("llene el form:", VIDEO_OUTPUT_FILE);
    return axios({
        method: 'POST',
        url: endpoints.validateVideo,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version,
            'tipo_prueba': localStorage.getItem("tipoPrueba")

        },
        data: data
    });
}

export function enviarAudioRostro(audio, frame, Acreated, Aexpire, isIOS) {
    console.log("isIOS:", isIOS);
    var data = new FormData();
    data.append("uuidUser", localStorage.getItem("uuidUser"));
    data.append("created", Acreated);
    data.append("expire", Aexpire);
    data.append("originOS", "android");
    data.append("tipo_prueba", localStorage.getItem("tipoPrueba"));
    //data.append("standard_deviation", localStorage.getItem("stdDev"));
    data.append("service_call", "videotoken-only-frame-audio");
    data.append("mejor_frame", frame, "frame-" + (new Date().getTime()) + ".png");

    //video
    let FECHA_UNICA = new Date().getTime();
    let AUDIO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    data.append("audio_video_token", audio, AUDIO_OUTPUT_FILE);
    console.log("llene el form:", AUDIO_OUTPUT_FILE);
    return axios({
        method: 'POST',
        url: endpoints.validateVideo,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version,
            'tipo_prueba': localStorage.getItem("tipoPrueba")

        },
        data: data
    });
}

export function sendSTD(std) {
    return axios({
        method: 'post',
        url: endpoints.actualizarSTD,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version
        },
        crossdomain: true,
        data: {
            "transaccion": localStorage.getItem("uuidTrx"),
            "standard_deviation": std,
        }
    });
}

export function sendTime(time) {
    return axios({
        method: 'post',
        url: endpoints.actualizarSTD,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version
        },
        crossdomain: true,
        data: {
            "transaccion": localStorage.getItem("uuidTrx"),
            "tiempo": time,
        }
    });
}

export function enviarVideo(facefile) {
    let FECHA_UNICA = new Date().getTime();
    let VIDEO_OUTPUT_FILE = FECHA_UNICA + 'recording.webm';
    var formData = new FormData();
    formData.append('file', facefile, (VIDEO_OUTPUT_FILE));
    formData.append("bucket", "dco-bucket-prod");
    //formData.append('bucket', 'assets');
    formData.append('folder', "7f6b6912-0c34-4dee-8034-d3e3b4edefd3/" + localStorage.getItem("uuidUser") + "/videotoken_spoof");
    return axios({
        method: 'POST',
        url: endpoints.files,
        headers: {
            'Content-Type': 'multipart/form-data',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version

        },
        data: formData
    });
}

/*STATUS PIVOTE*/

export const initStatus = () => {
    //console.log("entre a initStatus");
    updateInitStatus();
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: "inicio_page",
            result: "OK",
            //data: { transaccion: localStorage.getItem("uuidTrx")}
        }), new URL(window.location).searchParams.get("referer"))
        //console.log("hay referer");
        window.opener.postMessage(JSON.stringify({
            step: "inicio",
            result: "OK",
            data: {
                transaccion: localStorage.getItem("uuidTrx")
            }
        }), new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
        localStorage.setItem("externalJWT", new URL(window.location).searchParams.get("jwt"))
    }
    if (new URL(window.location).searchParams.get("referer")) {
        localStorage.setItem("externalReference", new URL(window.location).searchParams.get("referer"))
    }
    if (new URL(window.location).searchParams.get("schema")) {
        localStorage.setItem("package", new URL(window.location).searchParams.get("schema"))
    }
}

const updateInitStatus = async () => {
    //setLoading(true)
    let id_t = localStorage.getItem("uuidTrx")
    if (id_t === null) {
        //console.log("se creo nuevo trx");
        id_t = uuidv4();
        localStorage.setItem("uuidTrx", id_t)
    }
    let otorgante_t = localStorage.getItem("apikeyOtorgante");
    let uuid_t = localStorage.getItem("uuidUser");
    let json = {
        "status": "OK",
        "step": "inicio"
    }
    let event_t = JSON.stringify(json)
    //console.log("uuidtx:", localStorage.getItem("uuidTrx"));
    actualizarEstado(id_t, otorgante_t, uuid_t, "inicio_page", event_t).then((data) => {
        if (data.status === 200) {
            //console.log("Se actualizo status INIT");
        }
    }).catch((e) => {
        //console.log("No se pudo actualizar init Status. "+e);
        //setLoading(false)
    })
}

export const status = (paso, updatepaso) => {
    let json = {
        "status": "OK",
        "step": updatepaso
    }
    let event_t = JSON.stringify(json)
    updateStatus(paso, event_t);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK"
        }), localStorage.getItem("externalReference"))
    }
}

export const statusData = (paso, datos) => {
    var json = {};
    json = {
        "status": "OK",
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)
    updateStatus(paso, event_t);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            result: "OK",
            data: datos
        }), localStorage.getItem("externalReference"))
    }
}

export const statusError = (paso, datos, tipo) => {
    //console.log("entre a status cancelar");
    var json = {};
    let status = "INCOMPLETED"
    if (tipo !== "cancelado") {
        status = "FAIL"
    }
    json = {
        "status": status,
        "step": paso,
        "description": datos
    }
    let event_t = JSON.stringify(json)
    updateStatus(paso, event_t);
    if (window.opener) {
        window.opener.postMessage(JSON.stringify({
            step: paso,
            status: status,
            type: "PROCESS",
            error_text: datos,
        }), localStorage.getItem("externalReference"))
        if (tipo === "cancelado")
            window.close()
    }
}

const updateStatus = async (paso, event_t) => {
    //setLoading(true)
    let id_t = localStorage.getItem("uuidTrx")
    let otorgante_t = localStorage.getItem("apikeyOtorgante");
    let uuid_t = localStorage.getItem("uuidUser")
    actualizarEstado(id_t, otorgante_t, uuid_t, paso, event_t).then((data) => {
        if (data.status === 200) {
            //console.log("Se actualizo status:",event_t);
        }
    }).catch((e) => {
        console.log("No se pudo actualizar Status. " + e);
        //setLoading(false)
    })
}

function actualizarEstado(id_t, otorgante_t, uuid_t, paso, event_t) {
    var data = JSON.stringify({
        "trx_id": id_t,
        "company_id": otorgante_t,
        "client_id": uuid_t,
        "step": paso,
        "event": event_t
    });
    return axios({
        method: 'post',
        url: endpoints.actualizarEstado,
        headers: {
            'Content-Type': 'application/json',
            'transaccion': localStorage.getItem("uuidTrx"),
            'cliente': localStorage.getItem("uuidUser"),
            'apikey': localStorage.getItem("apikeyOtorgante"),
            'app_version': version

        },
        data: data
    });
}