import React, { useState } from "react";
import { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import { obtenerParametrosOtorgante, status } from '../services/api.js'
import { isMobile } from 'react-device-detect';

const Finalizado = () => {
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState("")
    const [dev, setDev] = useState(0)
    const [liveness, setLiveness] = useState(false)
    const [mov, setMov] = useState(0)
    const [faceInfo, setfaceInfo] = useState([])
    const [hayCookies] = useState(navigator.cookieEnabled);
    const url = 'https://' + window.location.hostname + "/images/agnes.png";
    const styles = [
        'background-image: url(' + url + ');',
        'background-size: cover',
        'color: #fff',
        'padding: 10px',
        'line-height: 70px',
        'width: 70px',
        'heigth: 140px',
        'border: 5px solid black',
    ];

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }
        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let face = JSON.parse(localStorage.getItem("face"))
        setfaceInfo(face)

        let dOtorgante = localStorage.getItem("dataOtorgante")
        //console.log("dOtorgante",dOtorgante);
        if (!dOtorgante || dOtorgante.length < 3) {
            //console.log("entre a pedir estuilos");
            obtenerParametrosOtorgante(apiKey, apiKey).then((data) => {
                if (data.status === 200) {
                    let dataOtorgante = data.data.payload
                    configStyle(dataOtorgante)
                    if (hayCookies) {
                        localStorage.setItem("dataOtorgante", JSON.stringify(dataOtorgante))
                    }
                    let logo = (obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', ""));
                    //console.log("logo", logo);
                    document.getElementById("imagenLogo").src = logo;
                }
                //setLoading(false)
            }).catch(() => {
                //setCorrectApikey(false);
            })
        }
        //console.log('%c Este codigo funciona!', styles.join(';'));
        
        
            console.log("face.liveness", face.liveness);
            console.log("face.movimientoBoca", face.movimientoBoca);
    
            if (face.liveness === "Vivo") {
                console.log("si mov");
                setMov(2)
                setLiveness(true)
            } else {
                console.log("spoof desde back");
                setMov(0)
                setLiveness(false)
            }

        status("videotoken", "Finalizado");

    }, [])



    return (
        <div className="main_gradient">
            <Header ruta="finalizado" />
            <div className="main_text_container">
                <h1 className="animate__animated animate__fadeIn animate__delay-1s">Verifica tu resultado</h1>
                <br />
                <p className="animate__animated">Has completado con éxito el proceso, muchas gracias.</p>
                <br />
                {
                    ((localStorage.getItem("apikeyOtorgante") === "tmIerl5JiLpbbx0ntRBC7cjoBeDobQep")) ?
                        <p className="animate__animated">Coincidencia: <b>{faceInfo.match === "True" ? "OK" : "No superada"}</b>
                            <br></br>
                            Liveness: <b>{liveness ? "OK" : "Spoof"}</b></p>
                        :
                        <div>
                            <p className="animate__animated"><b>{liveness ? "Persona real" : "Spoof detected"}</b></p>
                        </div>
                }
            </div>
            <div className="main_icon_container">
                <br /><br />
                <p className="text-center" ><small>Folio</small><br />
                    <b className="folioGde">{uuid.split("-")[0]}</b>
                </p>
            </div>
            <br />
            <div className={isMobile ? "action_buttons" : "action_buttons_desk"}>
                <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-3s" href={(localStorage.getItem("apikeyOtorgante") === "tmIerl5JiLpbbx0ntRBC7cjoBeDobQep") ? "https://" + window.location.host + "/" + apiKey + "?transaccion=" + localStorage.getItem("uuidTrx") : window.location.href.replace("finalizado", "") + localStorage.getItem("apikeyOtorgante")}>
                    TERMINAR
                </a>
            </div>
            <Footer />
        </div>
    )
}



export default Finalizado
