import React, { Fragment, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../services/configStyle'

const Header = (props) => {
    //process.env.PUBLIC_URL + '/images/wl/comp_logo.png'
    const [dataOtorgante, setDataOtorgante] = useState([]);
    const [hayCookies] = useState(navigator.cookieEnabled);
    const [pathname, setpathname] = useState('');

    useEffect(() => {
        let host = window.location.pathname;
        setpathname(host)

        if(hayCookies){
            let dataOtorganteLS = localStorage.getItem("dataOtorgante")
            if (dataOtorganteLS != null) {
                setDataOtorgante(JSON.parse(dataOtorganteLS))
                configStyle(JSON.parse(dataOtorganteLS))
            }
        }
        // //console.log(pathname);
        
    }, []);

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    return (
        <Fragment>
            <div id="head_container">
                <img id="imagenLogo" className="comp_logo animate__animated animate__slideInDown" src={(obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', "") != "") ? obtenerValorConfig(dataOtorgante, 'LOGO_OTORGANTE', process.env.PUBLIC_URL + '/images/wl/comp_logo.png') : process.env.PUBLIC_URL + '/images/wl/comp_logo.png'} alt="" />
            </div>
        </Fragment>
    );
}

export default Header;